import type { Ref } from 'vue'
import type { IMissingDataResponse } from '~/composables/useAPI/types'
import type { DashboardQuery, IDashboardRequest } from '@dashboard'
import { DashboardFiltersSymbol } from '@dashboard'
import type { SizeUnit, LeaseType, Sector, LeaseStatus } from '@register'
import type { ISearchPropertyLeasePortfolioRequest } from '@portfolio'
import { queryOptions, useQuery } from '@tanstack/vue-query'
import type { ReminderType } from '@critical-dates'

export interface IDashboardResultExpiryProfile {
  [year: string]: {
    [area: string]: {
      count: number
      link: ISearchPropertyLeasePortfolioRequest
    }
  }
}

export interface IDashboardResultExpiryProfileByArea {
  [year: string]: {
    [area: string]: {
      totalArea: number
      percentageOfArea: number
      sizeUnit: SizeUnit
      link: ISearchPropertyLeasePortfolioRequest
    }
  }
}

export interface IDashboardResultExpiryProfileByRent {
  [year: string]: {
    [area: string]: {
      totalCurrentRent: number
      percentageOfArea: number
      link: ISearchPropertyLeasePortfolioRequest
    }
  }
}

export interface IDashboardResultAverageInitialRent {
  [year: string]: {
    [area: string]: {
      average: number
      link: ISearchPropertyLeasePortfolioRequest
    }
  }
}

export interface IDashboardResultGeographicExposure {
  [area: string]: {
    value: number
    link: ISearchPropertyLeasePortfolioRequest
  }
}

export interface IDashboardResultExpiringAndHeldOver {
  heldOver: {
    count: number
    link: ISearchPropertyLeasePortfolioRequest
  }
  lessThanSixMonths: {
    count: number
    link: ISearchPropertyLeasePortfolioRequest
  }
  oneToTwoYears: {
    count: number
    link: ISearchPropertyLeasePortfolioRequest
  }
  sixMonthsToAYear: {
    count: number
    link: ISearchPropertyLeasePortfolioRequest
  }
}

export interface IDashboardResultTotalLeases {
  count: number
  totalCount: number
  link: ISearchPropertyLeasePortfolioRequest
}

export interface IDashboardResultGLA {
  totalActiveLeases: {
    count: number
    link: ISearchPropertyLeasePortfolioRequest
  }
  totalGla: {
    count: number
    value: number
    sizeUnit: SizeUnit
    link: ISearchPropertyLeasePortfolioRequest
  }
}

export interface IDashboardResultWale {
  count: number
  value: number
  link: ISearchPropertyLeasePortfolioRequest
}

export interface IDashboardResultTopLessors<
  T extends
    | DashboardQuery.TOP_LESSORS
    | DashboardQuery.TOP_LESSORS_BY_RENT = any,
> {
  data: IDashboardResultTopLessorsItem<T>[]
}

export type IDashboardResultTopLessorsItem<
  T extends
    | DashboardQuery.TOP_LESSORS
    | DashboardQuery.TOP_LESSORS_BY_RENT = any,
> = {
  landlord: string
  percentage: number
  link: ISearchPropertyLeasePortfolioRequest
} & (T extends DashboardQuery.TOP_LESSORS
  ? { totalArea: number }
  : T extends DashboardQuery.TOP_LESSORS_BY_RENT
    ? { totalCurrentRent: number }
    : {})

export interface IDashboardResultTopLessees<
  T extends
    | DashboardQuery.TOP_LESSEES
    | DashboardQuery.TOP_LESSEES_BY_RENT = ant,
> {
  data: IDashboardResultTopLesseesItem<T>[]
}

export type IDashboardResultTopLesseesItem<
  T extends
    | DashboardQuery.TOP_LESSEES
    | DashboardQuery.TOP_LESSEES_BY_RENT = any,
> = {
  tenant: string
  percentage: number
  link: ISearchPropertyLeasePortfolioRequest
} & (T extends DashboardQuery.TOP_LESSEES
  ? { totalArea: number }
  : T extends DashboardQuery.TOP_LESSEES_BY_RENT
    ? { totalCurrentRent: number }
    : {})

export interface IDashboardResultMap {
  data: {
    bounds: {
      topLeft: {
        latitude: number
        longitude: number
      }
      bottomRight: {
        latitude: number
        longitude: number
      }
    }
    items: IDashboardResultMapLease[]
  }
}

export interface IDashboardResultMapLease {
  leaseId: string
  name: string
  status: LeaseStatus
  type: LeaseType
  lessees: string[]
  lessors: string[]
  sector: Sector
  publishedAt: string
  commencementDate: string
  expiryDate: string
  size: number | null
  propertyName: string
  propertyUnitName: string
  country: string
  state: string
  city: string
  address: string
  postcode: string
  completion: {
    totalFields: number
    approvedFields: number
    percentage: number
  }
  requiredCompletion: {
    totalFields: number
    approvedFields: number
    percentage: number
  }
  documentCount: number
  baseRent: number
  baseRentFrequency: string
  googleAddress: string
  longitude: number
  latitude: number
}

export interface IDashboardResultCriticalDates {
  data: IDashboardResultCriticalDatesItem[]
}

export interface IDashboardResultCriticalDatesItem {
  leaseId: string
  leaseName: string
  criticalDateType: ReminderType
  date: string
}

export type DashboardResponseResultsByType<T extends DashboardQuery> =
  T extends DashboardQuery.EXPIRING_AND_HELD_OVER
    ? IDashboardResultExpiringAndHeldOver
    : T extends DashboardQuery.TOTAL_LEASES
      ? IDashboardResultTotalLeases
      : T extends DashboardQuery.EXPIRING_AND_HELD_OVER
        ? IDashboardResultExpiringAndHeldOver
        : T extends DashboardQuery.GLA
          ? IDashboardResultGLA
          : T extends DashboardQuery.WALE
            ? IDashboardResultWale
            : T extends DashboardQuery.WALE_BY_RENT
              ? IDashboardResultWale
              : T extends DashboardQuery.EXPIRY_PROFILE
                ? IDashboardResultExpiryProfile
                : T extends DashboardQuery.EXPIRY_PROFILE_BY_AREA
                  ? IDashboardResultExpiryProfileByArea
                  : T extends DashboardQuery.EXPIRY_PROFILE_BY_RENT
                    ? IDashboardResultExpiryProfileByRent
                    : T extends DashboardQuery.AVERAGE_INITIAL_RENT
                      ? IDashboardResultAverageInitialRent
                      : T extends DashboardQuery.GEOGRAPHIC_EXPOSURE
                        ? IDashboardResultGeographicExposure
                        : T extends DashboardQuery.TOP_LESSORS
                          ? IDashboardResultTopLessors<DashboardQuery.TOP_LESSORS>
                          : T extends DashboardQuery.TOP_LESSORS_BY_RENT
                            ? IDashboardResultTopLessors<DashboardQuery.TOP_LESSORS_BY_RENT>
                            : T extends DashboardQuery.TOP_LESSEES
                              ? IDashboardResultTopLessees<DashboardQuery.TOP_LESSEES>
                              : T extends DashboardQuery.TOP_LESSEES_BY_RENT
                                ? IDashboardResultTopLessees<DashboardQuery.TOP_LESSEES_BY_RENT>
                                : T extends
                                      | DashboardQuery.MAP_ACTIVE_LEASES
                                      | DashboardQuery.MAP_EXPIRED_LEASES
                                      | DashboardQuery.MAP_ALL_LEASES
                                  ? IDashboardResultMap
                                  : T extends DashboardQuery.CRITICAL_DATES
                                    ? IDashboardResultCriticalDates
                                    : never

// TODO: Remove when API return complete interface
export interface IDashboardResponse<T = unknown> {
  results: T extends DashboardQuery
    ? DashboardResponseResultsByType<T>
    : Record<string, any>
  missingData: IMissingDataResponse
  templateId: string
}

interface IApiDashboardOptionsArgs<QUERY extends DashboardQuery> {
  companyId: string
  query: MaybeRefOrGetter<QUERY>
  filters?: MaybeRefOrGetter<DashboardFilters>
}

export const apiDashboardOptions = <QUERY extends DashboardQuery>({
  companyId,
  query,
  filters = {},
}: IApiDashboardOptionsArgs<QUERY>) => {
  return queryOptions({
    queryKey: ['companies', companyId, 'dashboard', query, filters],
    queryFn: ({ signal }) => {
      const { scope, state, sector } = toValue(filters)

      return fetchAPI<IDashboardResponse<QUERY>>(
        `/companies/${companyId}/dashboard/query`,
        {
          signal,
          method: 'POST',
          body: {
            scope,
            state,
            sector,
            query: toValue(query),
          } satisfies IDashboardRequest,
        },
      )
    },
    staleTime: 1000 * 30, // 30 seconds
  })
}

export const useApiDashboard = <QUERY extends DashboardQuery>(
  companyId: string,
  query: MaybeRefOrGetter<QUERY>,
) => {
  const filters = inject<Ref<DashboardFilters>>(DashboardFiltersSymbol)

  if (!filters) throw new Error('DashboardFiltersSymbol not provided')

  return useQuery(apiDashboardOptions({ companyId, query, filters }))
}

export const useApiDashboardStates = (companyId: string) => {
  return useQuery({
    queryKey: ['companies', companyId, 'dashboard', 'states'],
    queryFn: ({ signal }) =>
      fetchAPI<string[]>(`/companies/${companyId}/dashboard/states`, {
        signal,
        method: 'GET',
      }),
    staleTime: 0,
  })
}

export type DashboardFilters = Pick<
  IDashboardRequest,
  'state' | 'scope' | 'sector'
>

export const useDashboardFilters = () => {
  return useFilters<DashboardFilters>(() => ({
    state: null,
    scope: undefined,
    sector: null,
  }))
}
