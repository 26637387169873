/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DashboardQuery {
    EXPIRING_AND_HELD_OVER = 'expiringAndHeldOver',
    EXPIRY_PROFILE = 'expiryProfile',
    EXPIRY_PROFILE_BY_AREA = 'expiryProfileByArea',
    TOTAL_LEASES = 'totalLeases',
    GLA = 'gla',
    WALE = 'wale',
    AVERAGE_INITIAL_RENT = 'averageInitialRent',
    GEOGRAPHIC_EXPOSURE = 'geographicExposure',
    TO_REVIEW = 'toReview',
    TOP_LESSORS = 'topLessors',
    TOP_LESSEES = 'topLessees',
    MAP_ALL_LEASES = 'mapAllLeases',
    MAP_ACTIVE_LEASES = 'mapActiveLeases',
    MAP_OTHER_LEASES = 'mapOtherLeases',
    MAP_EXPIRED_LEASES = 'mapExpiredLeases',
    WALE_BY_RENT = 'waleByRent',
    EXPIRY_PROFILE_BY_RENT = 'expiryProfileByRent',
    TOP_LESSEES_BY_RENT = 'topLesseesByRent',
    TOP_LESSORS_BY_RENT = 'topLessorsByRent',
    GEOGRAPHIC_EXPOSURE_BY_RENT = 'geographicExposureByRent',
    AVERAGE_CURRENT_RENT = 'averageCurrentRent',
    CRITICAL_DATES = 'criticalDates',
}
